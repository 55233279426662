import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import * as Vue from 'vue';

import { addDocumentElements } from '@/utils/addDocumentElements';

import {
	UPDATE_SITE_DATA,
	ADD_DOCUMENT_ELEMENTS,
	NAVIGATE_TO_PATH,
} from '@zyro-inc/site-modules/constants/messageEvents';
import {
	dataQADirective,
	QA_DIRECTIVE_NAME,
} from '@zyro-inc/site-modules/directives/dataQaDirective';

import '@/assets/scss/global.scss';
import App from '@/App.vue';
import { fetchSiteData } from '@/utils/fetchSiteData';
import router from '@/router';
import store from '@/store';

if ('ResizeObserver' in window === false) {
	window.ResizeObserver = ResizeObserverPolyfill;
}

const app = Vue.createApp({
	mounted: () => {
		// 'site-engine-mount' custom event is used in prerender service
		// it notifies lambda that app is mounted and it could save the HTML output
		document.dispatchEvent(new Event('site-engine-mount'));
		// when all external dependencies are loaded, fire 'DOMContentLoaded', because some external scripts depend on it
		window.addEventListener('load', () => document.dispatchEvent(new Event('DOMContentLoaded')));
	},
	watch: {
		$route: {
			handler(route) {
				if (route && this.$store.state.website) {
					addDocumentElements({
						siteData: this.$store.state.website,
						path: route.path,
					});
				}
			},
			deep: true,
		},
	},
	render: () => Vue.h(App),
})
	.use(router)
	.use(store);

fetchSiteData().then((siteData) => {
	// Don't overwrite website if it's already set
	// Currently we have only 1 such condition - postMessage in builder preview sets website before this fetch
	if (siteData && !store.state.website) {
		addDocumentElements({
			siteData,
			path: router.currentRoute.value.path,
		});
		store.commit('setWebsite', {
			website: siteData,
		});
	}
}).finally(() => {
	app.directive(QA_DIRECTIVE_NAME, dataQADirective);
	app.mount('#app');
});

/**
 * Listen for message events to allow setting data externally
 * Used for previews
 */
window.addEventListener('message', ({ data }) => {
	if (typeof data !== 'object') {
		return;
	}

	if (data.type === UPDATE_SITE_DATA) {
		store.commit('setWebsite', {
			website: data.payload.siteData,
		});

		if (data.payload.path && data.payload.path !== router.currentRoute.value.path) {
			router.push({
				path: data.payload.path,
			});
		}

		store.dispatch('ecommerce/setShoppingCartItems', []);
	}

	// NAVIGATE_TO_PATH event is used by other internal services (site-position-service, screenshot-service)
	if (data.type === NAVIGATE_TO_PATH) {
		if (data.payload.path !== router.currentRoute.value.path) {
			router.push({
				path: data.payload.path,
			});
		}
	}

	if (data.type === ADD_DOCUMENT_ELEMENTS) {
		addDocumentElements({
			siteData: data.payload.siteData,
			path: router.currentRoute.value.path,
		});
	}
});
